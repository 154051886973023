.favorite-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
}
.favorite-container01 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/78786%20%5B2%5D-1500h.png");
  background-position: center;
}
.favorite-container02 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.favorite-container03 {
  flex: 0 0 auto;
  width: 1200px;
  height: 830px;
  display: flex;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  border-radius: 40px;
  background-color: #fbfbfb;
}
.favorite-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.favorite-container05 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.favorite-container06 {
  gap: 40px;
  flex: 0 0 auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
}
.favorite-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
}
.favorite-container08 {
  display: flex;
  align-items: center;
}
.favorite-image {
  width: 50px;
  object-fit: cover;
}
.favorite-text {
  color: rgb(81, 69, 101);
  font-size: 25px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 400;
}
.favorite-navlink {
  display: contents;
}
.favorite-icon {
  fill: var(--dl-color-primary-lila);
  width: 30px;
  cursor: pointer;
  height: 30px;
  text-decoration: none;
}
.favorite-text01 {
  color: var(--dl-color-primary-txt);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}
.favorite-container09 {
  gap: 40px;
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
}
.favorite-container10 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container11 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container12 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container13 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container14 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container15 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container16 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container17 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container18 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container19 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container20 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container21 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container22 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container23 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container24 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container25 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container26 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container27 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container28 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container29 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container30 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container31 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container32 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container33 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container34 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container35 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.favorite-container36 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container37 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container38 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container39 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container40 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container41 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container42 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container43 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container44 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container45 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container46 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container47 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container48 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container49 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container50 {
  flex: 0 0 auto;
  color: rgba(81, 69, 101, 0.8);
  width: 100%;
  height: auto;
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  justify-content: space-between;
}
.favorite-container51 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container52 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container53 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
}
.favorite-container54 {
  flex: 0 0 auto;
  width: 497px;
  height: 100%;
  display: flex;
  box-shadow: -5px 0px 10px 0px rgba(136, 27, 171, 0.4);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/neues%20projekt%20%5B3%5D-900h.png");
  background-position: center;
}
.favorite-container55 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.17);
}
.favorite-container56 {
  gap: 40px;
  flex: 0 0 auto;
  width: 317px;
  display: flex;
  flex-direction: column;
}
.favorite-container57 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.favorite-text34 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
}
.favorite-text35 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 1px;
}
