.top-bar-top-bar {
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: row;
  padding-bottom: 5px;
  justify-content: center;
  background-color: var(--dl-color-primary-lila);
}
.top-bar-link {
  display: contents;
}
.top-bar-container {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.top-bar-image {
  width: 30px;
  object-fit: cover;
}
.top-bar-text {
  color: var(--dl-color-primary-white);
}
.top-bar-menu {
  gap: 10px;
  flex: 1;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-login {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container001 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container002 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container003 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container004 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container005 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text01 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container006 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container007 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container008 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container009 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon02 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container010 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text02 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container011 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container012 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container013 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container014 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon04 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container015 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text03 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container016 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container017 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container018 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container019 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon06 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container020 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text04 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container021 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container022 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container023 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container024 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon08 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container025 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text05 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-details {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container026 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container027 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container028 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container029 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text06 {
  color: var(--dl-color-primary-lila);
  font-size: 12px;
  line-height: 1;
}
.top-bar-container030 {
  flex: 1;
  height: 2px;
  display: flex;
  opacity: 1;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0.00%,rgba(255, 255, 255, 0.3) 100.00%);
}
.top-bar-text07 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container031 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container032 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container033 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container034 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon10 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container035 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text08 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container036 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container037 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container038 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container039 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon12 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container040 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text09 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container041 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container042 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container043 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container044 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon14 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container045 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text10 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container046 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container047 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container048 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container049 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon16 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container050 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text11 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-upload {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container051 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container052 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container053 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container054 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon18 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container055 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text12 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container056 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container057 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container058 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container059 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text13 {
  color: var(--dl-color-primary-lila);
  font-size: 12px;
  line-height: 1;
}
.top-bar-container060 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0.00%,rgba(255, 255, 255, 0.3) 100.00%);
}
.top-bar-text14 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container061 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container062 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container063 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container064 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon20 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container065 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text15 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container066 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container067 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container068 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container069 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon22 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container070 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text16 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container071 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container072 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container073 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container074 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon24 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container075 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text17 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-status {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container076 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container077 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container078 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container079 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon26 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container080 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text18 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container081 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container082 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container083 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container084 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon28 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container085 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text19 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container086 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container087 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container088 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container089 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text20 {
  color: var(--dl-color-primary-lila);
  font-size: 12px;
  line-height: 1;
}
.top-bar-container090 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0.00%,rgba(255, 255, 255, 0.3) 100.00%);
}
.top-bar-text21 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container091 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container092 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container093 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container094 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon30 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container095 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text22 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-container096 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container097 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container098 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container099 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon32 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container100 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text23 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-favorit {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container101 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container102 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container103 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container104 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon34 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container105 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text24 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container106 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container107 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container108 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container109 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon36 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container110 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text25 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container111 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container112 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container113 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container114 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon38 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container115 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text26 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container116 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container117 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container118 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container119 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text27 {
  color: var(--dl-color-primary-lila);
  font-size: 12px;
  line-height: 1;
}
.top-bar-container120 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0.00%,rgba(255, 255, 255, 0.3) 100.00%);
}
.top-bar-text28 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container121 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 0.3;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container122 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container123 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container124 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-white);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.top-bar-icon40 {
  fill: var(--dl-color-primary-white);
  width: 10px;
  height: 10px;
}
.top-bar-container125 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text29 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.top-bar-cookie {
  width: 595px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container126 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container127 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container128 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-container129 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon42 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container130 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text30 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container131 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container132 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container133 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container134 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon44 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container135 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text31 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container136 {
  gap: 5px;
  flex: 1;
  display: flex;
  opacity: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container137 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container138 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container139 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon46 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container140 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text32 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container141 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container142 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container143 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container144 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-icon48 {
  fill: var(--dl-color-primary-lila);
  width: 10px;
  height: 10px;
}
.top-bar-container145 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text33 {
  color: var(--dl-color-primary-white);
  opacity: 0.3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-container146 {
  gap: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.top-bar-container147 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.top-bar-container148 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-white);
}
.top-bar-container149 {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text34 {
  color: var(--dl-color-primary-lila);
  font-size: 12px;
  line-height: 1;
}
.top-bar-container150 {
  flex: 1;
  height: 2px;
  display: flex;
  align-items: flex-start;
}
.top-bar-text35 {
  color: var(--dl-color-primary-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.top-bar-link1 {
  display: contents;
}
.top-bar-container151 {
  color: var(--dl-color-primary-txt);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 15px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 15px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-primary-white);
}
.top-bar-text36 {
  font-size: 14px;
}











