.checkbox-container {
  flex: 0 0 auto;
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-border);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-white);
}
.checkbox-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(45deg, rgb(194, 165, 246) 4.00%,rgb(95, 56, 164) 98.00%);
}












































