.cookie-more-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
}
.cookie-more-container01 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/78786%20%5B2%5D-1500h.png");
  background-position: center;
}
.cookie-more-container02 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.cookie-more-container03 {
  flex: 0 0 auto;
  width: 1200px;
  height: 830px;
  display: flex;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  border-radius: 40px;
  background-color: #fbfbfb;
}
.cookie-more-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-gray-white);
}
.cookie-more-container05 {
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.cookie-more-container06 {
  gap: 40px;
  flex: 0 0 auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookie-more-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: space-between;
}
.cookie-more-container08 {
  display: flex;
  align-items: center;
}
.cookie-more-image {
  width: 50px;
  object-fit: cover;
}
.cookie-more-text {
  color: rgb(81, 69, 101);
  font-size: 25px;
  font-style: normal;
  font-family: Rubik;
  font-weight: 400;
}
.cookie-more-navlink {
  display: contents;
}
.cookie-more-icon {
  fill: var(--dl-color-primary-lila);
  width: 30px;
  cursor: pointer;
  height: 30px;
  text-decoration: none;
}
.cookie-more-text01 {
  color: var(--dl-color-primary-txt);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}
.cookie-more-container09 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookie-more-container10 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.cookie-more-text02 {
  color: var(--dl-color-primary-txt);
  opacity: 0.6;
  font-size: 14px;
}
.cookie-more-textinput {
  color: var(--dl-color-primary-txt);
  width: 100%;
  background-color: transparent;
}
.cookie-more-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-image: linear-gradient(90deg, rgb(104, 64, 165) 1.00%,rgba(104, 64, 165, 0.39) 50.00%,rgb(104, 64, 165) 100.00%);
}
.cookie-more-container12 {
  gap: 5px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cookie-more-container13 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.cookie-more-text03 {
  color: var(--dl-color-primary-txt);
  opacity: 0.6;
  font-size: 14px;
}
.cookie-more-textinput1 {
  color: var(--dl-color-primary-txt);
  width: 100%;
  background-color: transparent;
}
.cookie-more-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-image: linear-gradient(90deg, rgb(104, 64, 165) 1.00%,rgba(104, 64, 165, 0.39) 50.00%,rgb(104, 64, 165) 100.00%);
}
.cookie-more-container15 {
  gap: 20px;
  flex: 0 0 auto;
  width: 497px;
  height: 100%;
  display: flex;
  box-shadow: -5px 0px 10px 0px rgba(136, 27, 171, 0.4);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/neues%20projekt%20%5B3%5D-900h.png");
  background-position: center;
}
.cookie-more-container16 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.17);
}
.cookie-more-container17 {
  gap: 40px;
  flex: 0 0 auto;
  width: 317px;
  display: flex;
  flex-direction: column;
}
.cookie-more-container18 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.cookie-more-text04 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
}
.cookie-more-text05 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 1px;
}
.cookie-more-container19 {
  flex: 0 0 auto;
  width: 400px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: var(--dl-color-primary-white);
}
.cookie-more-text13 {
  color: var(--dl-color-primary-lila);
}
